export const SET_INVITATION = '[USER] SET_INVITATION';
export const FETCHING_INVITATION = '[USER] FETCHING_INVITATION';
export const SET_INVITATION_ERROR = '[USER] SET_ERROR';
export const FETCH_INVITATION = '[USER] FETCH_INVITATION';

export const setInvitation = (invitationKey) => ({
  type: SET_INVITATION,
  payload: { invitationKey },
});

export const setFetchingInvitation = () => ({
  type: FETCHING_INVITATION,
});

export const setError = (error) => ({
  type: SET_INVITATION_ERROR,
  payload: { error },
});

export const fetchInvitation = (invitationKey) => ({
  type: FETCH_INVITATION,
  payload: { invitationKey },
});
