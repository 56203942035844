import { StatusCodes } from 'http-status-codes';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import getInvitationApi from '../../api/user.api';
import {
  setFetchingInvitation,
  setInvitation,
  setError,
  FETCH_INVITATION,
} from './actions';

const getInvitationAsync = async (invitationKey) =>
  // eslint-disable-next-line no-return-await
  await getInvitationApi(invitationKey)
    .then((response) => response)
    .catch((error) => error);

function* getInvitation({ payload }) {
  const { invitationKey } = payload;
  yield put(setFetchingInvitation());
  try {
    const response = yield call(getInvitationAsync, invitationKey);
    if (response.status === StatusCodes.OK) {
      yield put(setInvitation(response.data.result));
    }
  } catch (error) {
    yield put(setError(error));
  }
}

export function* watchGetInvitation() {
  yield takeEvery(FETCH_INVITATION, getInvitation);
}

export default function* rootSaga() {
  yield all([fork(watchGetInvitation)]);
}
