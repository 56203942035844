import {
  FETCHING_INVITATION,
  SET_INVITATION,
  SET_INVITATION_ERROR,
} from './actions';

const INIT_STATE = {
  invitation: {},
  isLoading: true,
  error: undefined,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_INVITATION:
      return {
        ...state,
        isLoading: false,
        error: '',
        invitation: action.payload.invitationKey,
      };
    case SET_INVITATION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case FETCHING_INVITATION:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    default:
      return { ...state };
  }
};
